import React from 'react';
import './AboutVideo.css';

const AboutVideo = () => {
    return (
        <div className="about-video-container">
            <iframe 
                className="about-video-iframe"
                src="https://www.youtube.com/embed/3njw-l9uVMY" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
                title="YouTube Video"
            ></iframe>
        </div>
    );
};

export default AboutVideo;
