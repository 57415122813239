import React from 'react';
import './Supporters.css';

const supporters = [ 'BRUNING', 'COTRIPAL', 'FOCKINK', 'KEPLER', 'SAUR', 'TROMINK', 'PREFEITURA'
];

const Supporters = () => {
  const firstRowSupporters = supporters.slice(0, 4);
  const secondRowSupporters = supporters.slice(4, 7);

  return (
    <section id="supporters">
      <h2 className="supporters-title">APOIADORES</h2>
      <div className="supporters-list">
        <div className="supporters-row">
          {firstRowSupporters.map((supporter, index) => (
            <div key={index} className={`supporter ${supporter.toLowerCase()}`}>
              <img
                src={require(`../assets/images/apoiadores/${supporter}.webp`)}
                alt={supporter}
                className="supporter-logo"
              />
            </div>
          ))}
        </div>
        <div className="supporters-row">
          {secondRowSupporters.map((supporter, index) => (
            <div key={index} className={`supporter ${supporter.toLowerCase()}`}>
              <img
                src={require(`../assets/images/apoiadores/${supporter}.webp`)}
                alt={supporter}
                className="supporter-logo"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Supporters;
