import React, { useState } from 'react';
import logo from '../assets/images/logo-forum.webp';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div className="logo-container">
          <img src={logo} alt="Logo do Fórum" className="logo" />
        </div>
        <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="#inscricoes" onClick={() => setMenuOpen(false)}>INSCRIÇÕES</a></li>
            <li><a href="#schedule" onClick={() => setMenuOpen(false)}>PROGRAMAÇÃO</a></li>
            <li><a href="#location" onClick={() => setMenuOpen(false)}>LOCALIZAÇÃO</a></li>
            <li><a href="#supporters" onClick={() => setMenuOpen(false)}>APOIADORES</a></li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        </div>
        <div className={`close-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>✕</div>
      </nav>
    </header>
  );
};

export default Header;
