import React from 'react';
import aboutImage from '../assets/images/about-imagem.webp';
import './About.css';

const About = () => {
  return (
    <div id="about" className="about-section">
      <div className="image-container">
        <img src={aboutImage} alt="Sobre" className="about-image" />
      </div>
    </div>
  );
};

export default About;
