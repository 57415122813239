import React, { useState, useEffect } from 'react';
import './SpeakerCard.css';

const SpeakerCard = ({ name, profession, photo, description, linkedin }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check the screen size on initial load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCardClick = () => {
    if (isMobile) {
      setIsOverlayVisible(true);
    }
  };

  const handleOverlayClose = (e) => {
    e.stopPropagation();
    setIsOverlayVisible(false);
  };

  return (
    <div 
      className="speaker-card" 
      onClick={handleCardClick} 
      onMouseEnter={!isMobile ? () => setIsOverlayVisible(true) : undefined}
      onMouseLeave={!isMobile ? () => setIsOverlayVisible(false) : undefined}
    >
      <img src={photo} alt={name} className="speaker-photo" />
      <div className="info">
        <h3 className="speaker-name">{name}</h3>
        <p className="speaker-profession">{profession}</p>
      </div>
      {isOverlayVisible && (
        <div className="overlay" onClick={handleOverlayClose}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleOverlayClose}>X</button>
            <h3 className="speaker-name">{name}</h3>
            <p className="speaker-description">{description}</p>
            <a href={linkedin} className="linkedin-button" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeakerCard;
