import React from 'react';
import ProgramEvent from './ProgramEvent';
import './ProgramDay.css';

const ProgramDay = ({ date, events }) => {
  return (
    <div className="program-day">
      <h3 className="program-day-date">{date}</h3>
      <div className="program-events">
        {events.map((event, index) => (
          <div key={index}>
            <ProgramEvent {...event} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramDay;
