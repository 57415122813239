import React from 'react';
import './Location.css';

const Location = () => {
  return (
    <section id="location">
      <h2>LOCALIZAÇÃO</h2>
      <div className="location-text">
        <p>
          O <span className="highlight-orange">Fórum de Inovação</span> ocorrerá na <span className="highlight-orange">AFUCOPAL - PANAMBI RS</span> dia <span className="highlight-orange">06</span> e <span className="highlight-orange">05</span> de setembro de 2024.
        </p>
        <p>
          Endereço: <span className="highlight-orange">R. Henrique Baal, 1800 - Pavão, Panambi - RS, 98280-000</span>
        </p>
      </div>
      <div className="map-container">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d112413.50652551497!2d-53.560570619836824!3d-28.300684465609034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x94fdac9ef37ac48f%3A0x35b068de8cfbfbe7!2sR.%20Henrique%20Baal%2C%201800%20-%20Pav%C3%A3o%2C%20Panambi%20-%20RS%2C%2098280-000!3m2!1d-28.3007092!2d-53.478169199999996!5e0!3m2!1spt-BR!2sbr!4v1721654543515!5m2!1spt-BR!2sbr" 
          className="responsive-iframe" 
          title="Mapa da localização do evento"
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
    </section>
  );
};

export default Location;
