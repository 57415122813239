import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SpeakerCard from './SpeakerCard';
import './Speakers.css';

const speakers = [
  {
    name: 'Angelin Adams',
    profession: 'CEO da Bruning e Presidente do Instituto Agregar.',
    photo: require('../assets/images/speakers/Angelin Adams.webp'),
    description: 'Angelin Adams é CEO da Bruning Tecnometal. Iniciou a sua carreira na em 1991 como jovem aprendiz, cursou técnico em mecânica. É formado em Engenharia Mecânica, pela Unijuí e possui MBAs em Gestão Empresarial e Gestão Financeira pela FGV. Durante sua carreira atuou nas áreas de engenharia e comercial, foi gestor da área de ferramentaria, até tornar-se CEO, em 2014.',
    linkedin: 'https://www.linkedin.com/in/angelin-adams-34980a10/'
  },
  {
    name: 'Eduardo Knorr',
    profession: 'Presidente da ACI - PANAMBI e Diretor da Hidropan.',
    photo: require('../assets/images/speakers/Eduardo Knorr.webp'),
    description: 'Presidente da Associação Comercial de Panambi - ACI e ACITEC de Panambi, Engenheiro Eletricista e Mestre em Sistemas de Potência pela PUC-RS, com MBA em Gestão Empresarial e Administração do Setor Elétrico pela FGV. Com mais de 30 anos de experiência em planejamento, tecnologia e mercado energético, é Diretor de Operações da Hidropan Distribuição e Geração de Energia. Foi membro do Conselho de Infraestrutura da FIERGS e do Comitê de Planejamento Energético do RS, além de professor no Colégio Evangélico Panambi e na UNIJUÍ.',
    linkedin: 'https://www.linkedin.com/in/eduardo-k-0a62a034/'
  },
  {
    name: 'Gil Giardelli',
    profession: 'Inovador, escritor',
    photo: require('../assets/images/speakers/Gil Giardelli.webp'),
    description: 'Giardelli, escritor, apresentador, inovador, um defensor do uso da tecnologia para melhorar a qualidade de vida das pessoas e atualmente aborda temas relacionados à Inteligência Artificial em sua nova escola. Com sua vasta experiência e conhecimento, Giardelli é um líder influente no campo da inovação e um palestrante requisitado em todo o mundo.',
    linkedin: 'https://www.linkedin.com/in/gilgiardelli/'
  },
  {
    name: 'Adilce Spode Keller',
    profession: 'Gerente de TI da Kepler Weber.',
    photo: require('../assets/images/speakers/Adilce Spode Keller.webp'),
    description: 'Gestora de TI na Kepler Weber. Profissional com mais de 20 anos de experiência, desde 2001 atuando com TI. Experiência/Vivência no desenvolvimento do Planejamento Estratégico de TI, Transformação Digital, Gestão de Ambientes complexos em grandes empresas, CAPEX, OPEX, Lean IT, melhoria contínua de processos minimizando custos e maximizando a experiência das organizações através de ferramentas na formação e gestão de equipes (interna e externa). Gestão de portfólio e projetos diversos, participei e coordenei projetos de Implementação de Software e Infraestrutura, Transformação Digital e fui Gerente de Projeto SAP. ',
    linkedin: 'https://www.linkedin.com/in/adilce-spode-keller-b5225731/'
  },
  {
    name: 'Alejandro Meyer',
    profession: 'Gerente Corporativo de PD&I na FOCKINK.',
    photo: require('../assets/images/speakers/Alejandro Meyer.webp'),
    description: 'Entusiasta por impulsionar a inovação digital e a transformação na indústria, concessionárias de energia e no agronegócio.  Atualmente responsável pela inovação e transformação digital como Gerente Corporativo de PD&I na FOCKINK. Mestre em Eletrotécnica - Universidade Técnica de Berlim, Alemanha, de 1985 a 1992. Graduação como European Energy Manager, Universidade do Rio de Janeiro (2016).',
    linkedin: 'https://www.linkedin.com/in/alejandromeyer/'
  },
  {
    name: 'Alexandre Gewehr',
    profession: 'Especialista Global em Manufatura Digital na AGCO Corporation.',
    photo: require('../assets/images/speakers/Alexandre Gewehr.webp'),
    description: 'TI Manager na AGCO, atuando em estratégias globais de Industrial Cloud e digitalização de manufatura. Graduado em Administração e MBA em Engenharia de Produção, com mais de 35 anos na indústria automotiva. Focado em eficiência, redução de perdas e aumento da qualidade. Desenvolve projetos de digitalização, rastreabilidade, MES, LES, WMS, APS, PDM, PLM, Capacidade Finita, OEE, TOC e automação industrial.',
    linkedin: 'https://www.linkedin.com/in/alexandre-gewehr-8570078/'
  },
  {
    name: 'Aline Bairros',
    profession: 'Gestora de Pessoas na TROMINK.',
    photo: require('../assets/images/speakers/Aline Bairros.webp'),
    description: 'Gerente de Gestão de Pessoas na Tromink Industrial Ltda, administradora, pós-graduada em coaching e gestão de pessoas, possui ampla experiência em Recursos Humanos e gestão da mudança organizacional.',
    linkedin: 'https://www.linkedin.com/in/aline-bairros-33235397/'
  },
  {
    name: 'André Luis Neckel',
    profession: 'Gerente Executivo de Negócios na Embratel/CLARO.',
    photo: require('../assets/images/speakers/André Luis Neckel.webp'),
    description: 'André Luis Neckel é líder de engenharia da Embratel/Claro S.A, onde atua desde 2001. Com mestrado em engenharia de processos e tecnologias, possui mais de 20 anos de experiência no desenvolvimento e integração de diversas tecnologias de TI e Telecomunicações, com foco em projetos de inovação, soluções digitais e de inteligência aplicada a diversas áreas de negócio B2B.',
    linkedin: 'https://www.linkedin.com/in/andr%C3%A9-luis-neckel-a4156220/'
  },
  {
    name: 'Antônio Carlos Navarro',
    profession: 'Gerente de Produto na IBM',
    photo: require('../assets/images/speakers/Antônio Carlos Navarro.webp'),
    description: 'Formado em Física e Engenharia com MBA em Marketing, tem mais de 30 anos de experiência em tecnologia e inovação. Atualmente, é Evangelista para Soluções Cognitivas na IBM. Foi Professor de Gestão Estratégica de TI e atuou em centenas de projetos de inovação. Participa de Bluehacks e Meetups sobre Inteligência Artificial e Deep Learning constantemente. Atualmente, é Gerente de Produto para os servidores IBM Power Systems.',
    linkedin: 'https://www.linkedin.com/in/antonio-navarro-ibm/'
  },
  {
    name: 'Carlos Cesar Santos Jr',
    profession: 'Sócio Fundador da Startup Datawake Digital.',
    photo: require('../assets/images/speakers/Carlos Cesar Santos Jr.webp'),
    description: 'Sócio Fundador da Startup Datawake Digital, evolui digitalmente a indústria brasileira, integrando sistemas autônomos inteligentes com a expertise humana para gerar resultados extraordinários.',
    linkedin: 'https://www.linkedin.com/in/carloscesarjr/'
  },
  {
    name: 'Carlos Frees',
    profession: 'Diretor Presidente da Smart Free’s',
    photo: require('../assets/images/speakers/Carlos Venicius Frees.webp'),
    description: 'Carlos Venicius Frees é Diretor Presidente da Smart Free’s e consultor em certificação de Municípios e Projetos de Inovação. Mestre em Administração pela UNB/DF, com especializações em Inteligência Artificial e Gestão Estratégica pela PUC/RS e URI/RS, respectivamente. Atua como professor de Cidades Inteligentes no LARES/UFRJ. Destaca-se na certificação de cidades como São José dos Campos e Pindamonhangaba, além de liderar projetos inovadores em tecnologia e sustentabilidade urbana.',
    linkedin: 'https://www.linkedin.com/in/carlosfrees/'
  },
  {
    name: 'Daniel Mocelin',
    profession: 'Gerente de Engenharia de Processos e Foot Print na Randon.',
    photo: require('../assets/images/speakers/Daniel Mocelin.webp'),
    description: 'Gerente de Engenharia de Processos e Foot Print na Randon.',
    linkedin: 'https://www.linkedin.com/in/danielmocelin/'
  },
  {
    name: 'Danillo Barbosa',
    profession: 'Business Solution Consultant.',
    photo: require('../assets/images/speakers/Danillo Barbosa.webp'),
    description: 'Mecatrônico e engenheiro Mecânico cursando pós graduação em Gestão Estratégica de negócios, Desde 2014, trabalha no mercado brasileiro de tecnologias para automação de engenharias com diferentes tipos de negócios, como agroindustrial, automotivo, máquinas e equipamentos. Neste tempo, auxiliou centenas de empresas a desenvolverem projetos e processos complexos. Atualmente, auxilia no processo de transformação digital e na implementação de PLM nas empresas.',
    linkedin: 'https://www.linkedin.com/in/danillo-barbosa/'
  },
  {
    name: 'Diego Boelter',
    profession: 'Gerente de Inovação da Cotripal.',
    photo: require('../assets/images/speakers/Diego Boelter.webp'),
    description: 'Gerente de Tecnologia e Inovação na Cotripal, CTO e membro do conselho técnico da plataforma SmartCoop, com mais de duas décadas de experiência, especializado em integrar tecnologia e inovação às estratégias de negócios. Especialista em Gestão Estratégica do Agronegócio pela ESCOOP e Liderança na Inovação pela Tel Aviv University, mestrando em Gestão e Negócios pela UNISINOS, com foco em estratégias competitivas em ecossistemas digitais. Atua também como pesquisador e professor nas áreas de tecnologia, inovação e estratégia.',
    linkedin: 'https://www.linkedin.com/in/diego-boelter-a6830628/'
  },
  {
    name: 'Diego Schlindwein',
    profession: 'Head de Operações e fundador da Startup Sirros IoT.',
    photo: require('../assets/images/speakers/Diego Schlindwein.webp'),
    description: 'Head de Operações e fundador da Startup Sirros IoT, soluções para a indústria 4.0.',
    linkedin: 'https://www.linkedin.com/in/diegoiot/'
  },
  {
    name: 'Eduardo Peres',
    profession: 'Founder e Diretor de Inovação na DB.',
    photo: require('../assets/images/speakers/Eduardo Peres.webp'),
    description: 'Cofundador e Diretor de Inovação da DB, empresa de ambidestria organizacional e transformação digital. Consultor em estratégia, dados, inovação e tecnologias futuras. Graduado e mestre em Ciência da Computação pela UFRGS, com mais de 30 anos de experiência como professor, ministra Design Thinking, gerenciamento de projetos e métodos ágeis e MBAs na PUCRS. ',
    linkedin: 'https://www.linkedin.com/in/eduardoperes/'
  },
  {
    name: 'Eduardo Vieira',
    profession: 'Especialista em Excelência Operacional.',
    photo: require('../assets/images/speakers/Eduardo Vieira.webp'),
    description: 'Com mais de 15 anos de experiência nas indústrias metalmecânica e calçadista, Eduardo é um especialista em Excelência Operacional com liderança em projetos de otimização de processos e redução de custos e desenvolvimento de estratégias corporativas de Excelência Operacional.',
    linkedin: 'https://www.linkedin.com/in/eduardo-vieira-69261271/'
  },
  {
    name: 'Fabio Ghidini',
    profession: 'Chief Information Officer da Fockink.',
    photo: require('../assets/images/speakers/Fabio Ghidini.webp'),
    description: 'CIO da Fockink, atuando em estratégias digitais, integração de sistemas e automação. Graduado em Sistemas de Informação e MBA em Gestão empresarial, mais de 30 anos de experiência na Industria, atuando em todas as áreas da Gestão de TI, como Infraestrutura, Suporte, Gerenciamento de Projetos, Desenvolvimento de Sistemas, Integração de Sistemas Industriais ao MES e ERP e relacionamento com as áreas de negocio.',
    linkedin: 'https://www.linkedin.com/in/fabio-ghidini-849a69/'
  },
  {
    name: 'Fernanda Damiani Toledo',
    profession: 'Gerente de difusão da plataforma SmartCoop.',
    photo: require('../assets/images/speakers/Fernanda Damiani Toledo.webp'),
    description: 'Engenheira Agrônoma - Gerente de difusão da plataforma SmartCoop.',
    linkedin: 'https://www.linkedin.com/in/fernanda-damiani-toledo/?originalSubdomain=br'
  },
  {
    name: 'Gabriel Fragali de Castro',
    profession: 'Geotecnólogo na GeoSurvey.',
    photo: require('../assets/images/speakers/Gabriel Fragali de Castro.webp'),
    description: 'Geotecnólogo (UFPEL), atua nas áreas convencionais da geomensura e nas áreas arquitetônica e civil com levantamentos por laser scanner, tratamento e modelagem de nuvens de pontos.',
    linkedin: 'https://www.linkedin.com/in/gabriel-fragali-573b181a3/'
  },
  {
    name: 'Geomar Mateus Corassa',
    profession: 'Research Manager na CCGL.',
    photo: require('../assets/images/speakers/Geomar Mateus Corassa.webp'),
    description: 'Research Manager na CCGL, Rede Técnica Cooperativa - RTC e SmartCoop.',
    linkedin: 'https://www.linkedin.com/in/geomar-mateus-corassa-95007767/'
  },
  {
    name: 'Guillermo Dawson',
    profession: 'Diretor superintendente da CCGL e Diretor Executivo da SmartCoop.',
    photo: require('../assets/images/speakers/Guillermo Dawson.webp'),
    description: 'Diretor superintendente da CCGL e Diretor Executivo da SmartCoop.',
    linkedin: 'https://www.linkedin.com/in/guillermo-dawson-jr-b370211b9/'
  },
  {
    name: 'João Vitor Richa',
    profession: 'Coordenador de Farmer na AEVO | Impulsionando inovação nas empresas.',
    photo: require('../assets/images/speakers/João Vitor Richa.webp'),
    description: 'Engenheiro de Produção, atua na AEVO há 5 anos e hoje é responsável por desenhar processos de inovação em uma base de mais de 200 grandes empresas.',
    linkedin: 'https://www.linkedin.com/in/joaoricha/'
  },
  {
    name: 'Jorge Duarte V. Roldão',
    profession: 'Professor Associado da Fundação Dom Cabral, FDC',
    photo: require('../assets/images/speakers/Jorge Duarte Veiga Roldão.webp'),
    description: 'Mestre em Administração, Educação e Psicopedagogia, com especialização em Psicopedagogia. Possui um MBA em Marketing e é graduado em Administração de Empresas. Desde 2010, atua como Professor Associado na Fundação Dom Cabral, colaborando com diversas empresas em programas customizados e MBA.',
    linkedin: 'https://www.linkedin.com/in/jorge-duarte-roldão-9b9a0b22/'
  },
  {
    name: 'José Renato Hopf',
    profession: 'Sócio Diretor Executivo da Raiô Benefícios, Presidente do South Summit Brazil e do Grupo Four.',
    photo: require('../assets/images/speakers/José Renato Hopf.webp'),
    description: 'José Renato liderou a inovação no Banrisul e, após várias iniciativas pioneiras, fundou a Getnet, o primeiro unicórnio brasileiro. Em 2015, criou a 4all, especializada em experiências digitais, banking and payments, e plataformas. Hoje, é cofundador do Grupo Four, incluindo 4all, Uhuu e Wine Locals, e Sócio Diretor Executivo da Raiô. Também preside o South Summit Brazil e o Conselho da Amcham RS, além de atuar em diversas entidades empresariais.',
    linkedin: 'https://www.linkedin.com/in/jose-renato-hopf-4all/'
  },
  {
    name: 'Luiz Ferndando Comar',
    profession: 'Senior Cross Software Partner Technical Specialist (PTS) na IBM',
    photo: require('../assets/images/speakers/Luiz Fernando Comar.webp'),
    description: 'Senior Cross Software Partner Technical Specialist (PTS) na IBM, com sólida experiência no mercado de telecomunicações, possui conhecimento técnico profundo em soluções como 5G, IoT, NFVI e Arquitetura Infra-Cloud.',
    linkedin: 'https://www.linkedin.com/in/luiz-ferndando-comar/'
  },
  {
    name: 'Maiquel Roberto Schneider',
    profession: 'Analista de Tecnologia da Informação da TROMINK.',
    photo: require('../assets/images/speakers/Maiquel Roberto Schneider.webp'),
    description: 'Analista de TI na Tromink, atuando como Gerente de Projetos nos processos de Transformação Digital e Tecnologias. Graduado em Licenciatura em Computação e Pós graduado em Gestão de Tecnologia da Informação pelo IGTI / Insituto XP de Educação de Minas Gerais. Mais de 20 anos de experiência em todas as áreas de TI, focando mais nos últimos anos em Processos de Transformação Digital e Processos Estratégicos de TI, com especialidades em diversas ferramentas da Industria, MES, WMS, APS, PLM, BPMN, OEE.',
    linkedin: 'https://www.linkedin.com/in/maiquelschneiderti/overlay/photo/'
  },
  {
    name: 'Matheus Zeuch',
    profession: 'Chief Innovation Officer at SAP Labs LA',
    photo: require('../assets/images/speakers/Matheus Zeuch.webp'),
    description: 'Matheus Zeuch é um líder experiente no campo da tecnologia e inovação. Com formação em Sistemas de Informação e uma carreira diversificada de mais de 20 anos no setor, ele acumulou experiência em desenvolvimento de software, consultoria, gestão de equipes multidisciplinares, além de co-fundação e conselho de startups. Atualmente, ele exerce o cargo de Chief Innovation Officer na SAP Labs Latin America, onde é responsável por dirigir iniciativas estratégicas e promover uma cultura organizacional de inovação.',
    linkedin: 'https://www.linkedin.com/in/matheuszeuch/'
  },
  {
    name: 'Marcelo dos Santos',
    profession: 'Coordenador de Sistemas de Informação na Saur',
    photo: require('../assets/images/speakers/Marcelo dos Santos.webp'),
    description: 'Com mais de 15 anos de experiência em TI, lidera a área de sistemas de informação na Saur Equipamentos SA, unindo conhecimento técnico a uma visão estratégica. Formado em Sistemas para Internet e especializado em Tecnologia para Negócios pela PUC-RS, está em constante aperfeiçoamento com uma pós-graduação em Gestão de TI. Acredita que compreender o negócio é essencial para transformar tecnologia em inovação.',
    linkedin: 'https://www.linkedin.com/in/marcelo-dos-santos-b493013b/'
  },
  {
    name: 'Marcelo Nunes', 
    profession: 'Vice-Presidente de Negócios no Parque de Inovação Tecnológica São José dos Campos.',
    photo: require('../assets/images/speakers/Marcelo Nunes.webp'),
    description: 'Vice-Presidente de Negócios Engenheiro Florestal, com pós-graduação em Gestão Estratégica de Projetos pela FAAP e Gestão Ambiental e Desenvolvimento Sustentável pela Fatec Internacional, Diretor de Empresas na Anprotec, atualmente desempenha o papel de Vice-Presidente de Negócios no PIT – Parque de Inovação Tecnológica São José dos Campos, desde janeiro de 2022.',
    linkedin: 'https://www.linkedin.com/in/marcelo-nunes-6b9b5b20b/'
  },
  {
    name: 'Mauricio Cavalli',
    profession: 'Coordenador de TI na Bruning Tecnometal.',
    photo: require('../assets/images/speakers/Mauricio Cavalli.webp'),
    description: 'Atua em TI e automação desde 1999 e desde 2019 com Indústria 4.0 como gestor e arquiteto de soluções. Sócio de consultoria de ERP em SP por 4 anos e gerente de ERP LATAM por 7 anos em multinacional. Técnico em informática (QI), MCP (Microsoft, 2004), graduado em automação industrial (UCS, 2009), especialização em Administração (FSG, 2018), inovação (Unisinos, 2023). Atualmente cursa mestrado em Engenharia de Produção (Unisinos).',
    linkedin: 'https://www.linkedin.com/in/maur%C3%ADcio-cavalli/'
  },
  {
    name: 'Natália Vogt Galli',
    profession: 'Analista de Inovação na Cotripal.',
    photo: require('../assets/images/speakers/Natália Vogt Galli.webp'),
    description: 'Analista de Inovação na Cotripal e Agente de difusão SmartCoop.',
    linkedin: 'https://www.linkedin.com/in/nat%C3%A1lia-vogt-galli-3509081aa/'
  },
  {
    name: 'Rafael Pelli',
    profession: 'Diretor no IEBT Corporate.',
    photo: require('../assets/images/speakers/Rafael Pelli.webp'),
    description: 'Diretor no IEBT Corporate, IEBT Inovação.',
    linkedin: 'https://www.linkedin.com/in/rafael-pelli/'
  },
  {
    name: 'Rodrigo Dalla Vecchia',
    profession: 'CEO da Startup EV COMX.',
    photo: require('../assets/images/speakers/Rodrigo Dalla Vecchia.webp'),
    description: 'CEO da Startup EV COMX - desenvolve tecnologias inovadoras para digitalizar a indústria e os negócios, solucionando problemas em produção, logística e vendas através de Indústria 4.0, Pesquisa Operacional, Big Data e Machine Learning.',
    linkedin: 'https://www.linkedin.com/in/rodrigo-dalla-vecchia/'
  },
  {
    name: 'Sidnei Baronio',
    profession: 'Head de Transformação Digital na STIHL por 10 anos.',
    photo: require('../assets/images/speakers/Sidnei Baronio.webp'),
    description: 'Engenheiro Mecânico e Mestre em Simulação Computacional - Gestão de Produção ambos pela UFRGS. Mais de 30 anos de experiência em operações e  planejamento industrial, Gestão de Riscos e Transformação Digital. Projetos nos EUA, China, HongKong, Alemanha e Brasil. 10 anos como Head de Transformação Digital na área de operações da STIHL do Brasil.',
    linkedin: 'https://www.linkedin.com/in/sidnei-baronio/'
  },
  {
    name: 'Thiago Al Makul',
    profession: 'Coordenador de Vendas Matterport.',
    photo: require('../assets/images/speakers/Thiago Makul.webp'),
    description: 'Coordenador de Vendas na Matterport.',
    linkedin: 'https://www.linkedin.com/in/thiago-al-makul-ba35b421/'
  },
  {
    name: 'Tiago Sartori',
    profession: 'Vice-presidente da Cotripal.',
    photo: require('../assets/images/speakers/Tiago Sartori.webp'),
    description: 'Produtor Rural & Vice-presidente da Cotripal e Membro do Conselho Gestor de Inovação da CCGL e SmartCoop.',
    linkedin: 'https://www.linkedin.com/in/tiago-sartori-67a2b667/'
  },
  {
    name: 'Vagner Anabor',
    profession: 'Pesquisador brasileiro na área de Meteorologia na UFSM.',
    photo: require('../assets/images/speakers/Vagner Anabor.webp'),
    description: 'O professor Vagner Anabor, meteorologista brasileiro, estuda tempestades e Sistemas Convectivos de Mesoescala, responsáveis por 90% da precipitação convectiva na América do Sul. Com doutorado em Física, ele publicou mais de 50 artigos e possui duas patentes. Suas pesquisas atuais focam em modelagem atmosférica e IA para estudar aerossóis da Amazônia e seu impacto nas tempestades.',
    linkedin: 'https://www.linkedin.com/in/vagner-anabor-7b911236/'
  },
  {
    name: 'Wikings Marcelo Machado',
    profession: 'Partner da Next47, fundo de VC sob gestão e criado pela Siemens',
    photo: require('../assets/images/speakers/Wikings Marcelo Machado.webp'),
    description: 'Começou sua carreira em 1997 na SoftExpert. Em 2002, juntou-se à Siemens e, em 2006, foi para a Datasul, participando de 12 M&As antes da fusão com a Totvs. Foi CEO da unidade de energia da Siemens (2013-2017). Em 2022, co-fundou a Citrino Ventures. É técnico em processamento de dados, bacharel em economia, mestre em estatística e especializado em Venture Capital, com vasta experiência em M&A, infraestrutura de dados e cibersegurança. ​',
    linkedin: 'https://www.linkedin.com/in/wikings-marcelo-machado-396630141/'
  }
];

const Speakers = () => {
  const [cardsPerSlide, setCardsPerSlide] = useState(6);

  const updateCardsPerSlide = () => {
    if (window.innerWidth < 768) {
      setCardsPerSlide(1);
    } else if (window.innerWidth < 1024) {
      setCardsPerSlide(4);
    } else {
      setCardsPerSlide(6);
    }
  };

  useEffect(() => {
    updateCardsPerSlide();
    window.addEventListener('resize', updateCardsPerSlide);
    return () => {
      window.removeEventListener('resize', updateCardsPerSlide);
    };
  }, []);

  const renderSlides = () => {
    const slides = [];
    for (let i = 0; i < speakers.length; i += cardsPerSlide) {
      slides.push(
        <div key={i} className="carousel-slide">
          {speakers.slice(i, i + cardsPerSlide).map((speaker, index) => (
            <SpeakerCard
              key={index}
              name={speaker.name}
              profession={speaker.profession}
              photo={speaker.photo}
              description={speaker.description}
              linkedin={speaker.linkedin}
            />
          ))}
        </div>
      );
    }
    return slides;
  };

  return (
    <section className="speakers-section">
       <h2 className="speakers-title">PALESTRANTES</h2>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        useKeyboardArrows
        autoPlay={false}
        centerMode={false}
        emulateTouch
        showArrows
        swipeable
        dynamicHeight={false}
        selectedItem={0}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-prev">
              &lt;
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-next">
              &gt;
            </button>
          )
        }
      >
        {renderSlides()}
      </Carousel>
    </section>
  );
};

export default Speakers;