import React from 'react';
import './ProgramEvent.css';

const ProgramEvent = ({ time, title, description, speakers = [], moderator, link, logo }) => {
  // Combine speakers and moderator
  const allSpeakers = moderator ? [...speakers, { ...moderator, isModerator: true }] : speakers;

  // Determine layout based on number of speakers
  let firstRowSpeakers = allSpeakers;
  let secondRowSpeakers = [];
  if (allSpeakers.length === 6) {
    firstRowSpeakers = allSpeakers.slice(0, 3);
    secondRowSpeakers = allSpeakers.slice(3);
  }

  return (
    <div className="program-event">
      <div className="program-event-time">
        <p>{time}</p>
      </div>
      <div className="program-event-content">
        <h5 className="program-event-title">{title}</h5>
        {description && (
          <p className="program-event-description">
            {description} {link && <a href={link} target="_blank" rel="noopener noreferrer">aqui.</a>}
          </p>
        )}
        {logo && <img src={require(`../assets/images/${logo}`)} alt="Logo" className="program-event-logo" />}
        <div className={`program-speakers ${allSpeakers.length === 6 ? 'six-speakers' : ''}`}>
          {firstRowSpeakers.map((speaker, index) => (
            <div key={index} className={`program-speaker ${speaker.isModerator ? 'moderator' : ''}`}>
              {speaker.isModerator && <p className="program-moderator-title">Moderador:</p>}
              <a href={speaker.linkedin} target="_blank" rel="noopener noreferrer">
                <img src={speaker.photo} alt={speaker.name} className="program-speaker-photo" />
              </a>
              <div className="program-speaker-info">
                <p className="program-speaker-name">{speaker.name}</p>
                <p className="program-speaker-role">{speaker.role}</p>
              </div>
            </div>
          ))}
        </div>
        {secondRowSpeakers.length > 0 && (
          <div className="program-speakers">
            {secondRowSpeakers.map((speaker, index) => (
              <div key={index} className={`program-speaker ${speaker.isModerator ? 'moderator' : ''}`}>
                {speaker.isModerator && <p className="program-moderator-title">Moderador:</p>}
                <a href={speaker.linkedin} target="_blank" rel="noopener noreferrer">
                  <img src={speaker.photo} alt={speaker.name} className="program-speaker-photo" />
                </a>
                <div className="program-speaker-info">
                  <p className="program-speaker-name">{speaker.name}</p>
                  <p className="program-speaker-role">{speaker.role}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgramEvent;
