import React from 'react';
import Header from './components/Header';
import Home from './containers/Home';
import './assets/styles/index.css';
import './assets/styles/fonts.css'; // Importa o CSS das fontes

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
    </div>
  );
}

export default App;
