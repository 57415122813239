import React from 'react';
import ProgramDay from './ProgramDay';
import './ProgramSection.css';

const programData = [
  {
    date: '05 de Setembro',
    events: [
      {
        time: '08:00 às 12:00',
        title: 'Rodada de Negócios com SEBRAE',
        description: 'Inscreva-se para a Rodada de Negócios',
        link: 'https://portaldenegociosebrae.com.br/evento/da3a82df-88bf-42f7-91d0-5cefe5d98b5a',
        speakers: [{ name: 'SEBRAE', role: 'As Rodadas de Negócios promovidas pelo Sebrae/RS conectam empresas compradoras e vendedoras para criar parcerias comerciais.', photo: require('../assets/images/speakers/SEBRAE.webp') }]
      },
      {
        time: '13:00 às 13:30',
        title: 'Abertura Oficial',
        description: '',
        speakers: [
          { name: 'Angelin Adams', role: 'CEO da Bruning e Presidente do Instituto Agregar.', photo: require('../assets/images/speakers/Angelin Adams2.webp'), linkedin: 'https://www.linkedin.com/in/angelin-adams-34980a10/' },
          { name: 'Eduardo Knorr', role: 'Presidente da ACI-PANAMBI e Diretor da Hidropan.', photo: require('../assets/images/speakers/Eduardo Knorr.webp'), linkedin: 'https://www.linkedin.com/in/eduardo-k-0a62a034/' }
        ]
      },
      {
        time: '13:30 às 14:30',
        title: 'Empreendedorismo e construção de ecossistemas',
        description: '',
        speakers: [{ name: 'José Renato Hopf', role: 'Sócio Diretor Executivo da Raiô Benefícios, Presidente do South Summit Brazil e do Grupo Four.', photo: require('../assets/images/speakers/José Renato Hopf.webp'), linkedin: 'https://www.linkedin.com/in/jose-renato-hopf-4all/' }]
      },
      {
        time: '14:30 às 15:20',
        title: 'Segurança cibernética e rede 5G: Base para a transformação digital',
        description: '',
        speakers: [
          {name: 'Luiz Fernando Comar', role: 'Senior Cross Software Partner Technical Specialist (PTS) na IBM', photo: require('../assets/images/speakers/Luiz Fernando Comar.webp'), linkedin: 'https://www.linkedin.com/in/luiz-fernando-comar/' },
          { name: 'André Luis Neckel', role: 'Gerente Executivo de Negócios na Embratel/CLARO.', photo: require('../assets/images/speakers/André Luis Neckel.webp'), linkedin: 'https://www.linkedin.com/in/andr%C3%A9-luis-neckel-a4156220/' }
        ],
        moderator: { name: 'Mauricio Cavalli', role: 'Coordenador de TI na Bruning Tecnometal.', photo: require('../assets/images/speakers/Mauricio Cavalli.webp'), linkedin: 'https://www.linkedin.com/in/maur%C3%ADcio-cavalli/' }
      },
      {
        time: '15:20 às 15:40',
        title: 'Intervalo',
        description: 'Aproveite esse momento para visitar os stands das empresas inovadoras de nossa região!',
        speakers: []
      },
      {
        time: '15:40 às 16:30',
        title: 'O caminho da transformação digital',
        description: '',
        speakers: [
          { name: 'Alexandre Gewehr', role: 'Especialista Global em Manufatura Digital na AGCO Corporation.', photo: require('../assets/images/speakers/Alexandre Gewehr.webp'), linkedin: 'https://www.linkedin.com/in/alexandre-gewehr-8570078/' },
          { name: 'Sidnei Baronio', role: 'Head de Transformação Digital na STIHL por 10 anos.', photo: require('../assets/images/speakers/Sidnei Baronio.webp'), linkedin: 'https://www.linkedin.com/in/sidnei-baronio/' }
        ],
        moderator: { name: 'Alejandro Meyer', role: 'Gerente Corporativo de PD&I na FOCKINK.', photo: require('../assets/images/speakers/Alejandro Meyer.webp'), linkedin: 'https://www.linkedin.com/in/alejandromeyer/' }
      },
      {
        time: '16:30 às 17:15',
        title: 'Digitalização na manufatura',
        description: '',
        speakers: [{ name: 'Danillo Barbosa', role: 'Business Solution Consultant.', photo: require('../assets/images/speakers/Danillo Barbosa.webp'), linkedin: 'https://www.linkedin.com/in/danillo-barbosa/' }]
      },
      {
        time: '17:15 às 18:00',
        title: 'Impulsionando Inovação: O papel das startups na transformação tecnológica e industrial',
        description: '',
        speakers: [{ name: 'Wikings Marcelo Machado', role: ' Partner da Next47, fundo de VC sob gestão e criado pela Siemens.', photo: require('../assets/images/speakers/Wikings Marcelo Machado.webp'), linkedin: 'https://www.linkedin.com/in/wikings-marcelo-machado-396630141/' }]
      }
    ]
  },
  {
    date: '06 de Setembro',
    events: [
      {
        time: '08:00 às 09:00',
        title: 'Transformação digital nas empresas',
        description: '',
        speakers: [
          { name: 'Mauricio Cavalli', role: 'Coordenador de TI na Bruning Tecnometal.', photo: require('../assets/images/speakers/Mauricio Cavalli.webp'), linkedin: 'https://www.linkedin.com/in/maur%C3%ADcio-cavalli/' },
          { name: 'Maiquel R. Schneider', role: 'Analista de Tecnologia da Informação da TROMINK.', photo: require('../assets/images/speakers/Maiquel Roberto Schneider.webp'), linkedin: 'https://www.linkedin.com/in/maiquelschneiderti' },
          { name: 'Fabio Ghidini', role: 'Chief Information Officer da Fockink', photo: require('../assets/images/speakers/Fabio Ghidini.webp'), linkedin: 'https://www.linkedin.com/in/fabio-ghidini-849a69/' },
          { name: 'Diego Boelter', role: 'Gerente de Inovação da Cotripal.', photo: require('../assets/images/speakers/Diego Boelter.webp'), linkedin: 'https://www.linkedin.com/in/diego-boelter-a6830628/' },
          { name: 'Marcelo dos Santos', role: 'Analista de TI Sênior na Saur Equipamentos S.A.', photo: require('../assets/images/speakers/Marcelo dos Santos.webp'), linkedin: 'https://www.linkedin.com/in/marcelo-dos-santos-b493013b/' }
        ],
        moderator: { name: 'Adilce Spode Keller', role: 'Gerente de TI da Kepler Weber.', photo: require('../assets/images/speakers/Adilce Spode Keller.webp'), linkedin: 'https://www.linkedin.com/in/adilce-spode-keller-b5225731/' }
      },
      {
        time: '09:00 às 09:50',
        title: 'Tecnologia e resiliência: Inovando para prevenir e responder a catástrofes',
        description: '',
        speakers: [
          {name: 'Carlos Frees', role: 'Diretor Presidente da Smart Free’s', photo: require('../assets/images/speakers/Carlos Venicius Frees.webp'), linkedin: 'https://www.linkedin.com/in/carlosfrees/'},
          {name: 'Marcelo Nunes', role: 'Vice-Presidente de Negócios no PIT – Parque de Inovação Tecnológica São José dos Campos', photo: require('../assets/images/speakers/Marcelo Nunes.webp'), linkedin: 'https://www.linkedin.com/in/marcelo-nunes-da-silva-019a8b20/' },
          {name: 'Vagner Anabor', role: 'Pesquisador brasileiro na área de Meteorologia na UFSM.', photo: require('../assets/images/speakers/Vagner Anabor.webp'), linkedin: 'https://www.linkedin.com/in/vagner-anabor-7b911236/' }],
          moderator: { name: 'Aline Bairros', role: 'Gestora de Pessoas na TROMINK.', photo: require('../assets/images/speakers/Aline Bairros.webp'), linkedin: 'https://www.linkedin.com/in/aline-bairros-33235397/'}
      },
      {
        time: '09:50 às 10:10',
        title: 'Intervalo',
        description: 'Aproveite esse momento para visitar os stands das empresas inovadoras de nossa região!',
        speakers: []
      },
      {
        time: '10:10 às 11:00',
        title: 'Gêmeos digitais: Replicando o mundo real no virtual',
        description: '',
        speakers: [
          { name: 'Gabriel F. de Castro', role: 'Geotecnólogo na GeoSurvey.', photo: require('../assets/images/speakers/Gabriel Fragali de Castro.webp'), linkedin: 'https://www.linkedin.com/in/gabriel-fragali-573b181a3/' },
          { name: 'Thiago Al Makul', role: 'Coordenador de Vendas Matterport.', photo: require('../assets/images/speakers/Thiago Makul.webp'), linkedin: 'https://www.linkedin.com/in/thiago-al-makul-ba35b421/' }
        ]
      },
      {
        time: '11:00 às 12:00',
        title: 'Cultura e liderança digital: Capacitação e formação de talentos',
        description: '',
        speakers: [
          {name: 'Jorge D. V. Roldão', role: 'Mestre em Administração, Educação e Psicopedagogia.', photo: require('../assets/images/speakers/Jorge Duarte Veiga Roldão.webp'), linkedin: 'https://www.linkedin.com/in/santiago-andreuzza/' },
          {name: 'Matheus Zeuch', role: 'Chief Innovation Officer at SAP Labs LA', photo: require('../assets/images/speakers/Matheus Zeuch.webp'), linkedin: 'https://www.linkedin.com/in/matheuszeuch/' }
        ],
        moderator: { name: 'Alexandre Gewehr', role:'Especialista Global em Manufatura Digital na AGCO Corporation.', photo: require('../assets/images/speakers/Alexandre Gewehr.webp'), linkedin: 'https://www.linkedin.com/in/alexandre-gewehr-8570078/' }
      },
      {
        time: '13:00 às 13:30',
        title: 'Abertura - Atividade cultural',
        description: 'Um deslumbre da cultura Panambiense.',
        speakers: []
      },
      {
        time: '13:30 às 13:55',
        title: 'Industria 4.0 e inovação aberta: Como gerar resultados através da inovação?',
        description: '',
        speakers: [{ name: 'Rafael Pelli', role: 'Diretor no IEBT Corporate.', photo: require('../assets/images/speakers/Rafael Pelli.webp'), linkedin: 'https://www.linkedin.com/in/rafael-pelli/' }]
      },
      {
        time: '13:55 às 14:20',
        title: 'Transformação digital na indústria: Potencializando a produtividade com tecnologia',
        description: '',
        speakers: [{ name: 'Daniel Mocelin', role: 'Gerente de Engenharia de Processos e Foot Print na Randon.', photo: require('../assets/images/speakers/Daniel Mocelin.webp'), linkedin: 'https://www.linkedin.com/in/danielmocelin/' }]
      },
      {
        time: '14:20 às 14:40',
        title: 'Inovação contínua: Tecnologia e processos eficientes na era digital',
        description: '',
        speakers: [{ name: 'Eduardo Vieira', role: 'Especialista em Excelência Operacional.', photo: require('../assets/images/speakers/Eduardo Vieira.webp'), linkedin: 'https://www.linkedin.com/in/eduardo-vieira-69261271/' }]
      },
      {
        time: '14:40 às 15:00',
        title: 'Intervalo',
        description: 'Aproveite esse momento para visitar os stands das empresas inovadoras de nossa região!',
        speakers: []
      },
      {
        time: '15:00 às 16:00',
        title: 'Startups e indústria 4.0: Soluções inteligentes para desafios modernos',
        description: '',
        speakers: [
          { name: 'Rodrigo Dalla Vecchia', role: 'CEO da Startup EV COMX.', photo: require('../assets/images/speakers/Rodrigo Dalla Vecchia.webp'), linkedin: 'https://www.linkedin.com/in/rodrigo-dalla-vecchia/' },
          { name: 'Carlos Cesar Santos Jr', role: 'Sócio Fundador da Startup Datawake Digital.', photo: require('../assets/images/speakers/Carlos Cesar Santos Jr.webp'), linkedin: 'https://www.linkedin.com/in/carloscesarjr/' },
          { name: 'Diego Schlindwein', role: 'Head de Operações e fundador da Startup Sirros IoT.', photo: require('../assets/images/speakers/Diego Schlindwein.webp'), linkedin: 'https://www.linkedin.com/in/diegoiot/' }
        ],
        moderator: { name: 'João Vitor Richa', role: 'Coordenador de Farmer na AEVO | Impulsionando inovação nas empresas', photo: require('../assets/images/speakers/João Vitor Richa.webp'), linkedin: 'https://www.linkedin.com/in/joaoricha/' }
      },
      {
        time: '16:00 às 17:00',
        title: 'SmartCoop: A digitalização da propriedade rural',
        description: '',
        speakers: [
          { name: 'Geomar M. Corassa', role: 'Research Manager na CCGL.', photo: require('../assets/images/speakers/Geomar Mateus Corassa.webp'), linkedin: 'https://www.linkedin.com/in/geomar-mateus-corassa-95007767/' },
          { name: 'Tiago Sartori', role: 'Vice-presidente da Cotripal.', photo: require('../assets/images/speakers/Tiago Sartori.webp'), linkedin: 'https://www.linkedin.com/in/tiago-sartori-67a2b667/' },
          { name: 'Fernanda D. Toledo', role: 'Gerente de difusão da plataforma SmartCoop.', photo: require('../assets/images/speakers/Fernanda Damiani Toledo.webp'), linkedin: 'https://www.linkedin.com/in/fernanda-damiani-toledo/' },
          { name: 'Guillermo Dawson', role: 'Diretor superintendente da CCGL e Diretor Executivo da SmartCoop.', photo: require('../assets/images/speakers/Guillermo Dawson.webp'), linkedin: 'https://www.linkedin.com/in/guillermo-dawson-jr-b370211b9/' }
        ],
        moderator: { name: 'Natália Vogt Galli', role: 'Analista de Inovação na Cotripal.', photo: require('../assets/images/speakers/Natália Vogt Galli.webp'), linkedin: 'https://www.linkedin.com/in/nat%C3%A1lia-vogt-galli-3509081aa/' }
      },
      {
        time: '17:00 às 18:00',
        title: 'Transformação Digital',
        description: '',
        speakers:[{ name: 'Gil Giardelli', role: 'Inovador, escritor', photo: require('../assets/images/speakers/Gil Giardelli2.webp'), linkedin: 'https://www.linkedin.com/in/gilgiardelli/' }]
      },
      {
        time: '18:00 às 18:30',
        title: 'A transformação dos negócios pela Inteligência Artificial',
        description: '',
        speakers: [{ name: 'Eduardo Peres', role: 'Founder e Diretor de Inovação na DB.', photo: require('../assets/images/speakers/Eduardo Peres.webp'), linkedin: 'https://www.linkedin.com/in/eduardomperes/' }]
      }
    ]
  }
];

const ProgramSection = () => {
  return (
    <section id="schedule" className="program-section">
      <h2 className='program--section-title'>PROGRAMAÇÃO</h2>
      {programData.map((day, index) => (
        <ProgramDay key={index} date={day.date} events={day.events} />
      ))}
    </section>
  );
};

export default ProgramSection;
