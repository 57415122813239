import React from 'react';
import './RegistrationCard.css';

const RegistrationCard = ({ title, items, buttonText, buttonLink }) => {
  return (
    <div className="registration-card">
      <div className="registration-header">
        <h3 className="registration-title">{title}</h3>
        <div className="registration-line"></div>
      </div>
      <div className="registration-content">
        <ul className="registration-items">
          {items.map((item, index) => (
            <li key={index} className="registration-item" dangerouslySetInnerHTML={{ __html: item }}></li>
          ))}
        </ul>
      </div>
      <a href={buttonLink} className="registration-button" target="_blank" rel="noopener noreferrer">{buttonText}</a>
    </div>
  );
};

export default RegistrationCard;
