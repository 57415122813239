import React from 'react';
import About from '../components/About';
import Speakers from '../components/Speakers';
import Location from '../components/Location';
import Supporters from '../components/Supporters';
import RegistrationSection from '../components/RegistrationSection';
import ProgramSection from '../components/ProgramSection';
import AboutVideo from '../components/AboutVideo';

const Home = () => {
  return (
    <div>
      <About />
      <AboutVideo />
      <RegistrationSection/>
      <ProgramSection />
      <Speakers />
      <Location />
      <Supporters />
    </div>
  );
};

export default Home;
