import React from 'react';
import RegistrationCard from './RegistrationCard';
import './RegistrationSection.css';

const cards = [
  {
    title: 'FÓRUM DE INOVAÇÃO',
    items: [
      '<span class="highlight">Inspiração e Inovação:</span> Descubra insights valiosos e inspire-se com as mentes que estão moldando o futuro da nossa região e país.',
      '<span class="highlight">13h</span> às <span class="highlight">18h</span>',
      'Dia <span class="highlight">05</span> de setembro: <span class="highlight">08h</span> às <span class="highlight">18h</span>',
      'Dia <span class="highlight">06</span> de setembro: <span class="highlight">08h</span> às <span class="highlight">18h30</span>',
      '<span class="highlight">Afucopal - Panambi/RS</span>'
    ],
    buttonText: 'ADQUIRA SEU INGRESSO',
    buttonLink: 'https://www.sympla.com.br/evento/2-forum-de-inovacao/2554307'
  },
  {
    title: 'VISITAÇÃO',
    items: [
      '<span class="highlight">Explore as Inovações do Futuro:</span> Visite os Stands de empresas tecnológicas inovadoras do RS.',
      'Dia <span class="highlight">05</span> e <span class="highlight">06</span> de setembro',
      '<span class="highlight">08h</span> às <span class="highlight">18h</span>',
      '<span class="highlight">Afucopal - Panambi/RS</span>',
      '<span class="highlight">GRATUITO</span>',
      '<span class="highlight">ATENÇÃO: A VISITAÇÃO NÃO INCLUÍ O FÓRUM DE INOVAÇÃO</span>'
    ],
    buttonText: 'INSCREVA-SE',
    buttonLink: 'https://www.sympla.com.br/evento/visita-aos-estandes-durante-o-2-forum-de-inovacao/2555317'
  },
];

const RegistrationSection = () => {
  return (
    <section id="inscricoes">
      <h2 className="registration-section-title">INSCRIÇÕES</h2>
      <div className="line"></div>
     <div className="registration-section">  {cards.map((card, index) => (
        <RegistrationCard
          key={index}
          title={card.title}
          items={card.items}
          buttonText={card.buttonText}
          buttonLink={card.buttonLink}
        />
      ))}
      </div>
      <div>
      <h3 className='link-h3'>Quer participar da rodada de negócios? <a href='https://portaldenegociosebrae.com.br/evento/da3a82df-88bf-42f7-91d0-5cefe5d98b5a'  className="highlight-link" >INSCREVA-SE AQUI </a></h3>
      </div>
    </section>
  );
};

export default RegistrationSection;
